@charset "UTF-8";

/*----------------------------------------
woocommerce-checkout
----------------------------------------*/
.woocommerce {
  $this: &;

  &-checkout {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    text-align: justify;
    padding: 40px 0 0;

    @include is-tablet {
      display: block;
      padding: 20px 0 0;
    }

    #{$this}-privacy-policy-text {
      background: $white;
    }

    .wc-amazon-payments-advanced-populated {
      width: calc(100% - 320px);
      padding: 0 60px 0 0;

      @include is-tablet {
        width: 100%;
        padding: 0 0 40px;
      }

      @include is-mobile {
        padding: 0;
      }

      #{$this}-notices-wrapper {
        padding: 0;
      }

      .col2-set {
        width: 100%;
        padding: 0;

        h3:not([class]) {
          margin: 0 0 20px;
        }
      }
    }

    .col2-set {
      width: calc(100% - 320px);
      padding: 0 60px 0 0;

      @include is-tablet {
        width: 100%;
        padding: 20px 0 40px;
      }

      @include is-mobile {
        padding: 20px 0;
      }

      #{$this}-notices-wrapper {
        padding: 0;
      }

      h3:not([class]) {
        margin: 0;
      }

      .col-1 {
        margin: 0 0 20px;
      }
    }

    // scss-lint:disable IdSelector
    #order_review_heading {
      display: none;
    }

    &-review-order {
      width: 320px;
      // position: sticky;
      top: 0;
      background: $white-gray;
      padding: 30px;

      @include is-tablet {
        width: 100%;
      }

      &-table {
        width: 100%;
        margin: 0 0 20px;
        display: block;

        @include is-mobile {
          margin: 0 0 40px;
        }

        tr,
        th,
        td {
          display: block;
        }

        thead {
          display: none;
        }

        tfoot {
          border: solid $color-border;
          border-width: 1px 0;
          padding: 20px 0 0;
          display: block;
        }

        tr {
          margin: 0 0 20px;
        }

        th,
        td {
          text-align: left;
        }

        th {
          font-weight: $bold;
        }

        td {
          @include fz(18);

          @include is-mobile {
            @include fz(16);
          }
        }

        .tax_label,
        .includes_tax {
          @include fz(14);
          display: inline-block;
        }
      }

      .variation {
        font-size: 0;
        padding: 5px 10px 0 0;
        text-align: left;

        @include is-mobile {
          padding: 5px 10px 0 0;
        }

        p:not([class]) {
          line-height: 1.5;
          margin: 0;
        }

        dt,
        dd {
          @include fz(14);
          @include ls(.5);
          padding: 0 5px;

          @include is-mobile {
            @include fz(12);
          }
        }

        dt {
          line-height: 1.5;
          float: left;
        }

        dd {
          margin: 0 5px 5px 0;
          overflow: hidden;
        }
      }

      // scss-lint:disable IdSelector
      .button#place_order {
        @include fz(16);
        width: 100%;
        max-width: 100%;
        padding: 15px 0;
        margin: 20px auto;
      }
    }
  }

  &-additional-fields {
    margin: 0 0 20px;
  }
}
