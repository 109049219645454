@charset "UTF-8";

/*----------------------------------------
woocommerce-MyAccount
----------------------------------------*/
.woocommerce {
  $this: &;
  display: flex;
  flex-wrap: wrap;
  text-align: justify;
  width: calc(100% - 340px);
  padding: 0 0 0 80px;
  position: relative;
  z-index: 0;
  order: 2;

  @include is-hamburger {
    width: calc(100% - 250px);
    padding: 0 0 0 60px;
  }

  @include is-tablet {
    width: 100%;
    padding: 0;
  }

  @include is-mobile {
    padding: 0;
  }

  .p-option & {
    max-width: $outer-width;
    width: 100%;
    padding: 0 $side-padding;
    margin: 0 auto;

    @include is-hamburger {
      width: 100%;
    }

    @include is-mobile {
      padding: 0 $side-padding-mobile;
    }
  }
}
