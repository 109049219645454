@charset "UTF-8";

/*----------------------------------------
woocommerce-form
----------------------------------------*/
.woocommerce {
  $this: &;

  .woocommerce {
    &-form-row {
      width: 100%;
      text-align: left;
      margin: 0 0 20px;
    }

    &-ResetPassword {
      max-width: 480px;
      margin: 0 auto;
    }

    &-EditAccountForm {
      display: flex;
      flex-wrap: wrap;

      #{$this}-form-row {
        display: flex;
        flex-wrap: wrap;
        padding: 30px 0;
        margin: 0;
        border-bottom: 1px solid $color-border;

        @include is-mobile {
          padding: 10px 0 20px;
        }

        // &--first,
        // &--last {
        //   width: 50%;

        //   @include is-mobile {
        //     width: 100%;
        //   }
        // }

        // &--first {
        //   padding: 30px 30px 30px 0;

        //   @include is-mobile {
        //     padding: 10px 0 20px;
        //   }
        // }

        // &--last {
        //   padding: 30px 0 30px 30px;

        //   @include is-mobile {
        //     padding: 10px 0 20px;
        //   }
        // }
      }

      label {
        width: 30%;

        @include is-mobile {
          width: 100%;
        }

        + label {
          width: 70%;

          @include is-mobile {
            width: 100%;
          }
        }
      }

      input {
        width: 70%;
        padding: 10px;

        @include is-mobile {
          width: 100%;
        }

        &[name="account_display_name"] {
          + span {
            display: none;
          }
        }
      }

      fieldset {
        width: 100%;
        position: relative;
        text-align: left;
        padding: 20px 0 20px 30%;
        border-bottom: 1px solid $color-border;

        @include is-mobile {
          padding: 20px 0;
        }

        legend {
          width: 30%;
          padding: 20px 0;
          position: absolute;
          top: 10px;
          left: 0;

          @include is-mobile {
            width: 100%;
            position: static;
            padding: 20px 0 0;
          }
        }

        #{$this}-form-row {
          border-bottom: 0;
          padding: 10px 0;
        }

        label {
          @include fz(14);
          width: 100%;
          padding: 0;
          margin: 0 0 5px;

          &[for="password_current"],
          &[for="password_1"] {
            @include ls(-.5);
          }
        }
      }
    }

    &-password-hint {
      @include fz(14);
      text-align: justify;
      display: block;
    }

    &-password-strength {
      @include fz(12);
      display: inline-block;
      color: $white;
      background: $black;
      padding: 3px 10px 2px;
      border-radius: 5px;
      margin: 10px 0;

      &.short {
        background: $red;
        font-weight: $bold;
      }

      &.bad {
        background: $orange;
        font-weight: $bold;
      }

      &.strong {
        background: $green;
        font-weight: $bold;
      }
    }

    &-LostPassword {
      text-align: center;
    }
  }

  .password-input {
    display: block;
    width: 100%;
    position: relative;

    input {
      width: 100%;
    }
  }

  .show-password-input {
    font-size: 0;
    padding: 10px;
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
    transform: translateY(-50%);

    &.display-password {
      &::after {
        content: "\f06e";
      }
    }

    &::after {
      @include fz(17);
      content: "\f070";
      color: $black;
      font-family: "Font Awesome 5 Free";
      font-weight: 400;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
    }
  }
}
