@charset "UTF-8";

/*----------------------------------------
woocommerce-error
----------------------------------------*/
.woocommerce {
  $this: &;

  &-privacy-policy-text {
    @include fz(12);
    padding: 20px 20px 1px;
    background: $white-gray;
  }
}
