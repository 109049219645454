@charset "UTF-8";

/*----------------------------------------
woocommerce-address
----------------------------------------*/
.woocommerce {
  $this: &;

  &.is-difference {
    .product {
      .price {
        @include fz(20);
        background: $white-gray;
        padding: 15px 20px;
      }
    }
  }

  &-product {
    .woocommerce-notices-wrapper {
      padding: 0 40px;
      margin: 0 0 40px;
    }
  }

  .content-area {
    width: 100%;

    @include is-mobile {
      margin-top: 16px;
    }
  }

  .product {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .onsale {
      display: none;
    }

    .summary {
      width: 43%;
      padding: 0 0 0 80px;
      position: sticky;
      top: 0;

      @include is-tablet-large {
        width: 100%;
        position: relative;
        padding: 0;
        margin: 0;
      }

      @include is-mobile {
        width: 100%;
      }
    }

    .quantity {
      float: left;

      input {
        @include fz(16);
        width: 120px;
        text-align: center;
        padding: 18px 0 18px 16px;

        @include is-mobile {
          @include fz(14);
          width: 80px;
          padding: 15px 0;
        }
      }

      &:not(.hidden) + button {
        width: calc(100% - 120px);
        padding: 20px 0;

        @include is-mobile {
          width: calc(100% - 80px);
          padding: 16px 0;
        }
      }
    }

    &_meta {
      padding: 30px 0 0;

      > span {
        color: $gray02;
        display: block;
        margin: 0 0 10px;

        @include is-mobile {
          @include fz(12);
        }
      }
    }

    &_title {
      @include fz(20);
      margin: 0 0 10px;
      padding-right: 30px;

      @include is-mobile {
        @include fz(15);
      }
    }

    .woocommerce-variation-price {
      .price {
        @include fz(32);
        background: none;
        padding: 0;

        @include is-mobile {
          @include fz(28);
        }
      }
    }

    .price {
      @include fz(32);
      line-height: 1.2;
      margin: 0 0 50px;

      @include is-mobile {
        margin-bottom: 30px;
      }

      small {
        @include fz(13);
        margin: 0 0 0 5px;
      }

      del {
        @include fz(32);
        margin: 0 10px 10px 0;

        @include is-mobile {
          @include fz(28);
        }
      }

      ins {
        @include fz(32);
        text-decoration: none;

        @include is-mobile {
          @include fz(28);
        }

        // scss-lint:disable SelectorDepth
        .woocommerce-Price {
          // scss-lint:disable SelectorDepth
          &-amount {
            @include ls(2);
            color: $red;
          }

          // scss-lint:disable SelectorDepth
          &-currencySymbol {
            @include fz(18);
            margin: 0 0 0 2px;
          }
        }
      }

      .woocommerce-Price {
        &-amount {
          color: $black;
        }

        &-currencySymbol {
          @include fz(18);
          margin: 0 0 0 2px;
        }
      }
    }

    .variations_form {
      th,
      td {
        padding: 10px 0;
        vertical-align: middle;
      }

      select {
        width: 100%;
        padding: 10px 20px;

      }

      .label {
        width: 120px;
      }

      .variations {
        width: 100%;
        margin: -10px 0;
      }

      .reset_variations {
        @include fz(12);
        margin: 10px 0 0;
        padding: 6px 20px;
        background: $white-gray;
        border: 1px solid $color-border;
        // scss-lint:disable ImportantRule
        display: none !important;
      }
    }
  }

  &-product-gallery {
    width: 57%;
    position: relative;

    @include is-tablet-large {
      width: 100%;
    }

    .c-favorite-btn {
      width: 68px;
      height: 68px;
      top: 30px;
      right: 60px;

      @include is-tablet {
        width: 46px;
        height: 46px;
        top: 10px;
        right: 20px;
      }

      @include is-mobile {
        right: 10px;
      }

      &::before {
        border-width: 34px;

        @include is-mobile {
          border-width: 23px;
        }
      }

      &::after {
        @include fz(30);

        @include is-tablet {
          @include fz(24);
        }
      }
    }

    &__slide {
      margin: 0 0 20px;
      position: relative;

      @include is-tablet {
        width: 100vw;
        padding: 0;
        margin-left: -$side-padding;
        margin-right: -$side-padding;
        margin-bottom: 20px;
      }
  
      @include is-mobile {
        margin-left: -$side-padding-mobile;
        margin-right: -$side-padding-mobile;
      }

      .swiper-slide {
        padding: 80% 0 0;
        position: relative;

        div {
          background: no-repeat center $white-gray;
          background-size: contain;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }

      &-controls {
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        pointer-events: none;
        display: flex;
        justify-content: space-between;
        transform: translateY(-50%);
        z-index: 10;

        @include is-tablet {
          right: 0;
          left: 0;
        }

        &-next,
        &-prev {
          width: 50px;
          height: 50px;
          display: block;
          background: $black;
          border: 0;
          outline: none;
          cursor: pointer;
          pointer-events: auto;

          @include is-tablet {
            width: 38px;
            height: 38px;
          }

          &::after {
            content: "";
            display: inline-block;
            width: 10px;
            height: 10px;
            border: solid $white;
            border-width: 1px 1px 0 0;
            transform: translateX(-25%) rotate(45deg);
            transition: transform .2s ease;
          }
        }

        &-next {
          &:hover {
            &::after {
              transform: translateX(-5%) rotate(45deg);
            }
            
          }
        }

        &-prev {
          &::after {
            transform: translateX(25%) rotate(-135deg);
          }

          &:hover {
            &::after {
              transform: translateX(5%) rotate(-135deg);
            }
            
          }
        }
      }
    }

    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      margin: -5px;
    }

    &__image {
      font-size: 0;
      width: 25%;
      cursor: pointer;
      padding: 5px;

      a {
        position: relative;
        display: block;
        overflow: hidden;
        padding-bottom: 60%;

        &::before {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          display: block;
          background: rgba($black, .5);
          z-index: 2;
        }
      }

      &.is-active {
        a {
          &::before {
            content: '';
          }
        }
      }

      a {
        display: block;
        pointer-events: none;
      }

      img {
        width: 100%;
        position: absolute;
      }
    }
  }

  &-tabs {
    display: none;
  }

  &-variation {
    &-add-to-cart {
      margin: 20px 0 0;
    }
  }

  .single_variation_wrap {
    padding: 10px 0 0;
  }

  .stock {
    font-weight: $bold;
    margin: 5px 0 0;

    &.out-of-stock {
      color: $red;
    }
  }
}
