@charset "UTF-8";

/*----------------------------------------
woocommerce-error
----------------------------------------*/
.woocommerce {
  $this: &;

  .woocommerce {
    &-error {
      width: 100%;
      order: -1px;
      margin: 0 0 20px;

      li {
        @include fz(14);
        color: $white;
        background: $red;
        font-weight: $bold;
        padding: 13px 20px 12px;
        border-radius: 5px;

        @include is-mobile {
          padding: 14px 10px;
        }

        &:not(:last-child) {
          margin: 0 0 10px;
        }
      }

      a {
        color: $white;
        text-decoration: underline;
      }
    }
  }
}
