@charset "UTF-8";

/*----------------------------------------
Variables
----------------------------------------*/

/*
# Color */
$black: #111;
/* $black: #111; */
$white: #fff;
/* $white: #fff; */
$gray-black: #999;
/* $gray-black: #999; */
$gray-black2: #525252;
/* $gray-black2: #525252; */
$primary: #343434;
/* $primary: #343434; */
$primary-white: #777;
/* $primary-white: #777; */
$white-gray: #f5f5f5;
/* $white-gray: #f5f5f5; */
$white-gray2: #f9f9f9;
/* $white-gray2: #f9f9f9; */
$white-gray3: #eee;
/* $white-gray3: #eee; */
$gray-pale: #efefef;
/* $gray-pale: #efefef; */
$gray-light: #d0d0d0;
/* $gray-light: #d0d0d0; */
$gray-light02: #b2b2b2;
/* $gray-light02: #b2b2b2; */
$gray: #cccccc;
/* $gray: #cccccc; */
$gray02: #666;
/* $gray02: #666; */
$gray-dull: #707070;
/* $gray-dull: #707070; */
$gray-dark: #909090;
/* $gray-dark: #909090; */
$blue-gray: #cad5d9;
/* $blue-gray: #cad5d9; */
$red: #ab2d2d;
/* $red: #ab2d2d; */
$red-light: #bf3333;
/* $red-light: #bf3333; */
$red-black: #751313;
/* $red-black: #751313; */
$red-white: #d23d3d;
/* $red-white: #d23d3d; */
$orange: #ff5722;
/* $orange: #ff5722; */
$pink1: #dd4688;
/* $pink1: #dd4688; */
$pink2: #d46abf;
/* $pink2: #d46abf; */
$pink3: #cc8ef5;
/* $pink3: #cc8ef5; */
$green: #4caf50;
/* $green: #4caf50; */

/*

# Border */
$color-border: #ddd;
/* $color-border: #ddd; */
$color-border-black: #111;
/* $color-border-black: #111; */
$color-border-gray: #707070;
/* $color-border-gray: #707070; */

/*

/*

# Shadow */
$shadow: rgba(#777, .1);
/* $shadow: rgba(#777, .1); */
$shadow-hover: rgba(#777, .3);
/* $shadow-hover: rgba(#777, .3); */

/*

# Hover */
$opacity: .7;
/* $opacity: .7; */

/*

# Font Family */
$font: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
/* $font: 游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif */
$font-en: "Playfair Display", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
/* $font-en: "Playfair Display", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif; */
$font-jp: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
/* $font-jp: "Noto Sans JP", Arial, Helvetica, sans-serif; */
$font-cn: "Microsoft Yahei", "PingHei";
/* $font-cn: "Microsoft Yahei", "PingHei"; */

/*

# Font Weight */
$light: 300;
/* $light: 300; */
$regular:400;
/* $regular:400; */
$medium: 500;
/* $medium: 500; */
$bold: 900;
/* $bold: 900; */

/*

# Width */
$outer-width: 1920px;
/* $outer-width: 1920px; */
$content-width: 1280px;
/* $content-width: 1280px; */

/*

# Padding */
$side-padding: 80px;
/* $side-padding: 80px; */
$side-padding-mobile: 16px;
/* $side-padding-mobile: 16px; */

$header_height_tablet: 60px;

$header_height_mobile: 44px;

/*

# Easing */
$ease: cubic-bezier(.19, 1, .22, 1);
/* $ease: cubic-bezier(.19, 1, .22, 1); */

