@charset "UTF-8";

/*----------------------------------------
woocommerce-order-again
----------------------------------------*/
.woocommerce {
  $this: &;

  .order-again {
    .button {
      @include fz(18);
      font-family: $font;
      font-weight: $bold;
      width: 320px;
      max-width: 90%;
      padding: 20px 0;
      margin: 40px auto;
      display: block;
      color: $white;
      background: $gray02;
      border: 0;
      box-shadow: none;
      position: relative;
      transition: background .5s $ease;
      text-align: center;
      cursor: pointer;

      @include is-mobile {
        width: 248px;
        padding: 12px 0;
      }

      &:hover {
        background: $primary;
      }

      &:disabled {
        opacity: .5;
        cursor: auto;

        &:hover {
          background: $gray02;
        }

        &::before {
          display: none;
        }
      }
    }
  }
}
