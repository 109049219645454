@charset "UTF-8";

/*----------------------------------------
woocommerce-Price-amount
----------------------------------------*/
.woocommerce {
  $this: &;

  .woocommerce {
    &-Price-amount {
      @include fz(24);
      @include ls(1);
      font-family: $font-en;
      color: $red;

      @include is-mobile {
        @include fz(22);
      }

      .p-option & {
        @include fz(20);
      }


    }
  }
}
.p-option .woocommerce .woocommerce-cart-form__cart-item .woocommerce-Price-amount {
  @include is-mobile {
    margin-top: -8px;
    display: inline-block;
  }
}