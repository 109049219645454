@charset "UTF-8";

/*----------------------------------------
woocommerce-MyAccount
----------------------------------------*/
.woocommerce {
  $this: &;

  .woocommerce {
    &-MyAccount {
      &-navigation {
        width: 340px;

        @include is-tablet-large {
          width: 250px;
        }

        @include is-tablet {
          background: $white-gray;
          padding: 20px 40px;
          width: 100%;
          order: 2;
        }

        @include is-mobile {
          width: 100%;
          padding: 20px 20px 40px;
        }

        &-link {
          text-align: left;
          border-bottom: 1px solid $color-border;
          margin: 0 0 20px;

          @include is-mobile {
            margin: 0;
          }

          a {
            @include fz(18);
            color: $black;
            display: block;
            padding: 18px 0;
            text-decoration: none;
            transition: color .2s ease;

            &:hover {
              @include is-laptop {
                color: $red;
              }
            }

            @include is-mobile {
              @include fz(16);
            }
          }
        }
      }

      &-content {
        width: calc(100% - 340px);
        padding: 0 0 0 80px;

        @include is-tablet-large {
          width: calc(100% - 250px);
          padding: 0 0 0 60px;
        }

        @include is-tablet {
          width: 100%;
          order: 1;
          padding: 0 0 30px;
        }

        .u-column1 {
          padding-left: 0;
        }

        .u-column2 {
          padding-right: 0;
        }
      }
    }
  }
}
